import {HttpParams} from '@angular/common/http';
import {EnvironmentConstant} from '@rcms/constants';
import {CommonUtil} from './common.util';

export class ApiUtil {
  static convert2HttpParamsWithQuery(obj: unknown): HttpParams {
    return new HttpParams().set(
      'query',
      JSON.stringify(obj, (key, value) => CommonUtil.jsonReplacer(key, value)),
    );
  }

  static generateBackendUrlFromPath(path: string) {
    return `${EnvironmentConstant.API_BACKEND_URL}/${path}`;
  }

  static generateCachedUrlFromPath(path: string) {
    return `${EnvironmentConstant.API_CACHE_URL}/${path}`;
  }
}
