import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {CompanyService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ICompanyResponse} from '@rcms/types';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-company-delete-modal',
  templateUrl: './company-delete-modal.component.html',
  styleUrls: ['./company-delete-modal.component.scss'],
})
export class CompanyDeleteModalComponent extends BaseModalComponent<ICompanyResponse> implements OnInit {
  private companyService = inject(CompanyService);

  isLoading = false;
  company!: ICompanyResponse;

  ngOnInit() {
    this.company = this.data;
  }

  onBack() {
    this.clearFeedback();
  }

  onDelete() {
    this.isLoading = true;
    this.companyService.deleteCompanyByIdObs(this.company.id).subscribe({
      next: () => this.onDeleteCompanySuccess(),
      error: () => this.onDeleteCompanyFailed(),
    });
  }

  onDeleteCompanyFailed() {
    this.isLoading = false;
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.COMPANY);
  }

  onDeleteCompanySuccess() {
    this.isLoading = false;
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.COMPANY);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.COMPANY_DELETE);
  }
}
