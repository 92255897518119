import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {RouteConstant} from '@rcms/constants';
import {NavigationService, StateService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {catchError, Observable, throwError} from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private navigationService = inject(NavigationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        GlobalSignal.loading.set(false);
        if (error.status === 401) {
          // Token expired, redirect to login page
          this.navigationService.navigateToAsync(RouteConstant.ROUTE_NAME.LOGIN)
            .then(() => StateService.clearAll())
            .catch((e) => { throw e;});
        }
        if (error.status === 403) {
          this.navigationService.navigateToAsync(RouteConstant.ROUTE_NAME.FORBIDDEN).catch((e) => { throw e;});
        }

        return throwError(() => error?.error?.error);
      }),
    );
  }
}
