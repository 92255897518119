import {HelperConstant} from './helper.constant';

export class PermissionConstant {
  static readonly ACTION = {CREATE: 'create', DELETE: 'delete', READ: 'read', UPDATE: 'update'} as const;

  static readonly SCREEN_NAME = {
    STATIONS_MANAGEMENT: 'STATIONS_MANAGEMENT',
    USERS_MANAGEMENT: 'USERS_MANAGEMENT',
  } as const;

  static readonly SCREEN_SCOPE = {
    [PermissionConstant.SCREEN_NAME.STATIONS_MANAGEMENT]: {
      AREA: HelperConstant.ENTITY.AREA,
      STATION: HelperConstant.ENTITY.STATION,
      TRAIN_STATION: HelperConstant.ENTITY.TRAIN_STATION,
    },
    [PermissionConstant.SCREEN_NAME.USERS_MANAGEMENT]: {
      COMPANY: HelperConstant.ENTITY.COMPANY,
      ROLE: HelperConstant.ENTITY.ROLE,
      USER: HelperConstant.ENTITY.USER,
    },
  } as const;
}
