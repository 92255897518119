import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class FormValidatorService {
  static differentPasswordValidation(control: AbstractControl) {
    if (control.dirty) {
      const {passwordNew, passwordNewConfirm} = control.value;
      if (passwordNew === passwordNewConfirm) {
        return null;
      }
      return {differentPassword: true};
    }
    return null;
  }

  static samePasswordValidation(control: AbstractControl) {
    if (control.dirty) {
      const {passwordOld, passwordNew} = control.value;
      if (passwordOld !== passwordNew) {
        return null;
      }
      return {samePassword: true};
    }
    return null;
  }
}
