import {inject, Injectable} from '@angular/core';
import {CompanyApi} from '@rcms/apis';
import {
  ICompanyCreateRequest,
  ICompanyResponse,
  ICompanyUpdateRequest,
  ICountResponse,
  ILoopBackFilters,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CompanyService {
  private companyApi = inject(CompanyApi);

  createCompanyObs(requestBody: ICompanyCreateRequest): Observable<ICompanyResponse> {
    return this.companyApi.createCompany(requestBody);
  }

  deleteCompanyByIdObs(companyId: string): Observable<void> {
    return this.companyApi.deleteCompanyById(companyId);
  }

  getAllCompaniesObs(filters: ILoopBackFilters): Observable<ICompanyResponse[]> {
    return this.companyApi.getAllCompanies(filters);
  }

  getCountAllCompaniesObs(where: object): Observable<ICountResponse> {
    return this.companyApi.getCountAllCompanies(where);
  }

  updateCompanyByIdObs(companyId: string, requestBody: ICompanyUpdateRequest): Observable<ICompanyResponse> {
    return this.companyApi.updateCompanyById(companyId, requestBody);
  }
}
