import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {UserService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IUserResponse} from '@rcms/types';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-delete-modal',
  templateUrl: './user-delete-modal.component.html',
  styleUrls: ['./user-delete-modal.component.scss'],
})
export class UserDeleteModalComponent extends BaseModalComponent<IUserResponse> implements OnInit {
  private userService = inject(UserService);

  isLoading = false;
  user!: IUserResponse;

  ngOnInit() {
    this.user = this.data;
  }

  onBack() {
    this.clearFeedback();
  }

  onDelete() {
    this.isLoading = true;
    this.userService.deleteUserByIdObs(this.user.id).subscribe({
      next: () => this.onDeleteUserSuccess(),
      error: () => this.onDeleteUserFailed(),
    });
  }

  onDeleteUserFailed() {
    this.isLoading = false;
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.USER);
  }

  onDeleteUserSuccess() {
    this.isLoading = false;
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.USER);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.USER_DELETE);
  }
}
