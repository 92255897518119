import {computed, signal} from '@angular/core';
import {CommonConstant} from '@rcms/constants';
import {
  TPermissionsData,
  TPermissionsDataEntity,
  TPermissionStationsManagement,
  TPermissionUsersManagement,
  TRolePermissions,
} from '@rcms/types';
import {RoleUtil} from '@rcms/utils';

export class PermissionSignal {
  static permissionsRaw = signal<TRolePermissions | null>(null);

  static permissions = computed<TPermissionsData>(() => {
    const permissionsRaw = PermissionSignal.permissionsRaw();
    if (permissionsRaw) {
      return RoleUtil.getPermissionsData(permissionsRaw);
    }
    return RoleUtil.getPermissionsDataDefault();
  });

  static permissionsArea = computed<TPermissionsDataEntity>(() => PermissionSignal.permissions().Area);

  static permissionsCompany = computed<TPermissionsDataEntity>(() => PermissionSignal.permissions().Company);

  static permissionsTrainStation = computed<TPermissionsDataEntity>(() => PermissionSignal.permissions().TrainStation);

  static permissionsUser = computed<TPermissionsDataEntity>(() => PermissionSignal.permissions().User);

  static permissionsStationsManagement = computed<TPermissionStationsManagement>(() => {
    return {
      [CommonConstant.ENTITY.AREA]: PermissionSignal.permissions().Area,
      [CommonConstant.ENTITY.STATION]: PermissionSignal.permissions().Station,
      [CommonConstant.ENTITY.TRAIN_STATION]: PermissionSignal.permissions().TrainStation,
    };
  });

  static permissionsUsersManagement = computed<TPermissionUsersManagement>(() => {
    return {
      [CommonConstant.ENTITY.COMPANY]: PermissionSignal.permissions().Company,
      [CommonConstant.ENTITY.ROLE]: PermissionSignal.permissions().Role,
      [CommonConstant.ENTITY.USER]: PermissionSignal.permissions().User,
    };
  });

  static clear() {
    PermissionSignal.permissionsRaw.set(null);
  }
}
