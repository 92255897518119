import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {IUserLoginRequest, IUserLoginResponse, IUserLogoutRequest} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class AuthApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.USERS;

  login(requestBody: IUserLoginRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/login`);

    return this.httpClient.post<IUserLoginResponse>(url, requestBody);
  }

  logout(requestBody: IUserLogoutRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/logout`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery(requestBody);

    return this.httpClient.delete<void>(url, {params: queryParams});
  }
}
