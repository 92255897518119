import {inject, Injectable} from '@angular/core';
import {TrainStationApi} from '@rcms/apis';
import {
  ICountResponse,
  ILoopBackFilters,
  ITrainStationCreateRequest,
  ITrainStationResponse,
  ITrainStationUpdateRequest,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TrainStationService {
  private trainStationApi = inject(TrainStationApi);

  createTrainStationObs(requestBody: ITrainStationCreateRequest): Observable<ITrainStationResponse> {
    return this.trainStationApi.createTrainStation(requestBody);
  }

  deleteTrainStationByIdObs(id: string): Observable<void> {
    return this.trainStationApi.deleteTrainStationById(id);
  }

  getAllTrainStationsObs(filters: ILoopBackFilters): Observable<ITrainStationResponse[]> {
    return this.trainStationApi.getAllTrainStations(filters);
  }

  getCountAllTrainStationsObs(where: object): Observable<ICountResponse> {
    return this.trainStationApi.getCountAllTrainStations(where);
  }

  updateTrainStationByIdObs(id: string, requestBody: ITrainStationUpdateRequest): Observable<ITrainStationResponse> {
    return this.trainStationApi.updateTrainStationById(id, requestBody);
  }
}
