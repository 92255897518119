import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {AreaService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IAreaResponse} from '@rcms/types';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-area-delete-modal',
  templateUrl: './area-delete-modal.component.html',
  styleUrls: ['./area-delete-modal.component.scss'],
})
export class AreaDeleteModalComponent extends BaseModalComponent<IAreaResponse> implements OnInit {
  private areaService = inject(AreaService);

  isLoading = false;
  area!: IAreaResponse;

  ngOnInit() {
    this.area = this.data;
  }

  onBack() {
    this.clearFeedback();
  }

  onDelete() {
    this.isLoading = true;
    this.areaService.deleteAreaByIdObs(this.area.id).subscribe({
      next: () => this.onDeleteAreaSuccess(),
      error: () => this.onDeleteAreaFailed(),
    });
  }

  onDeleteAreaFailed() {
    this.isLoading = false;
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.AREA);
  }

  onDeleteAreaSuccess() {
    this.isLoading = false;
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.AREA);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.AREA_DELETE);
  }
}
