import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant, UserConstant} from '@rcms/constants';
import {RoleService, UserService} from '@rcms/services';
import {UserStore} from '@rcms/states/akita/stores';
import {IErrorResponse, IRoleResponse, IUserResponse, IUserUpdateRequest} from '@rcms/types';
import {CommonUtil} from '@rcms/utils';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-update-modal',
  templateUrl: './user-update-modal.component.html',
  styleUrls: ['./user-update-modal.component.scss'],
})
export class UserUpdateModalComponent extends BaseModalComponent<IUserResponse> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private roleService = inject(RoleService);
  private userService = inject(UserService);
  private userStore = inject(UserStore);

  form!: FormGroup;
  hasChange = false;
  isExistUsername = false;
  isLoading = false;
  roleDatasets: IRoleResponse[] = [];
  user!: IUserResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.user = this.data;
    this.patchDataForm(this.user);
    this.getRoleDatasets();
  }

  focusInputUsername() {
    this.isExistUsername = false;
  }

  getRoleDatasets() {
    this.roleService.getAllRolesObs({}).subscribe({
      next: (roles) => this.roleDatasets = roles,
    });
  }

  initForm() {
    this.form = this.fb.group({
      username: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      password: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      fullName: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      roleId: ['', Validators.required],
      active: [false],
    });
  }

  patchDataForm(data: IUserResponse) {
    const passwordDecrypted = CommonUtil.aesDecrypt(data.password, data.id);
    this.form.patchValue({
      username: data.username,
      password: passwordDecrypted,
      fullName: data.fullName,
      roleId: data.roleId,
      active: data.active,
    });
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    const isInvalid = this.form.invalid || this.isExistUsername;
    if (this.hasChange && !isInvalid) {
      this.isLoading = true;
      const requestBody: IUserUpdateRequest = this.form.value;
      this.userService.updateUserByIdObs(this.user.id, requestBody).subscribe({
        next: (data) => this.onUpdateUserSuccess(data),
        error: (error) => this.onUpdateUserFailed(error),
      });
    }
  }

  onUpdateUserFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistUsername = true;
    } else {
      this.setFeedbackUpdateFailed(ModalConstant.ENTITY.USER);
    }
  }

  onUpdateUserSuccess(user: IUserResponse) {
    this.userStore.update(user.id, user);
    this.isLoading = false;
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.USER);
  }
}
