import {signal} from '@angular/core';
import {CoreConstant} from '@rcms/constants';
import {IModalData, IUserResponse, TModalName} from '@rcms/types';

export class GlobalSignal {
  static forceReloadDataFromModal = signal<TModalName | null>(null, {equal: () => false});

  static loading = signal<boolean>(false);

  static me = signal<IUserResponse | undefined>(undefined);

  static meAvatarUrl = signal<string>(CoreConstant.IMAGES.USER_AVATAR);

  static modalData = signal<IModalData | null>(null);

  static sideBarShow = signal<boolean>(false);

  static token = signal<string>('');

  static clear() {
    GlobalSignal.forceReloadDataFromModal.set(null);
    GlobalSignal.loading.set(false);
    GlobalSignal.me.set(undefined);
    GlobalSignal.meAvatarUrl.set(CoreConstant.IMAGES.USER_AVATAR);
    GlobalSignal.modalData.set(null);
    GlobalSignal.sideBarShow.set(false);
    GlobalSignal.token.set('');
  }
}
