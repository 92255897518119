import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {
  ICountResponse,
  ILoopBackFilters,
  ITrainStationCreateRequest,
  ITrainStationResponse,
  ITrainStationUpdateRequest,
} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class TrainStationApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.TRAIN_STATIONS;
  private prefixCount = 'count';

  createTrainStation(requestBody: ITrainStationCreateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<ITrainStationResponse>(url, requestBody);
  }

  deleteTrainStationById(id: string) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${id}`);

    return this.httpClient.delete<void>(url);
  }

  getAllTrainStations(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<ITrainStationResponse[]>(url, {params: queryParams});
  }

  getCountAllTrainStations(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  updateTrainStationById(id: string, requestBody: ITrainStationUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${id}`);

    return this.httpClient.patch<ITrainStationResponse>(url, requestBody);
  }
}
