import {inject, Injectable} from '@angular/core';
import {RoleApi} from '@rcms/apis';
import {ICountResponse, ILoopBackFilters, IRoleResponse} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RoleService {
  private roleApi = inject(RoleApi);

  getAllRolesObs(filters: ILoopBackFilters): Observable<IRoleResponse[]> {
    return this.roleApi.getAllRoles(filters);
  }

  getCountAllRolesObs(where: object): Observable<ICountResponse> {
    return this.roleApi.getCountAllRoles(where);
  }
}
