import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {AreaService, CompanyService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IAreaResponse, IAreaCreateRequest, ICompanyResponse, IErrorResponse} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-area-create-modal',
  templateUrl: './area-create-modal.component.html',
  styleUrls: ['./area-create-modal.component.scss'],
})
export class AreaCreateModalComponent extends BaseModalComponent<IAreaResponse> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private areaService = inject(AreaService);
  private companyService = inject(CompanyService);

  form!: FormGroup;
  hasChange = false;
  isExistName = false;
  isInvalid = false;
  isLoading = false;
  companyDatasets: ICompanyResponse[] = [];

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.getCompanyDatasets();
  }

  focusInputName() {
    this.isExistName = false;
  }

  getCompanyDatasets() {
    this.companyService.getAllCompaniesObs({}).subscribe({
      next: (companies) => this.companyDatasets = companies,
    });
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      companyMaintenanceId: ['', [Validators.required]],
      companyObserverId: ['', [Validators.required]],
    });
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    this.isInvalid = this.form.invalid || this.isExistName;
    if (this.hasChange && !this.isInvalid) {
      this.isLoading = true;
      const requestBody: IAreaCreateRequest = this.form.value;
      this.areaService.createAreaObs(requestBody).subscribe({
        next: () => this.onCreateAreaSuccess(),
        error: (error) => this.onCreateAreaFailed(error),
      });
    }
  }

  onCreateAreaFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistName = true;
    } else {
      this.setFeedbackCreateFailed(ModalConstant.ENTITY.AREA);
    }
  }

  onCreateAreaSuccess() {
    this.isLoading = false;
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.AREA);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.AREA_CREATE);
  }
}
