import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {IAreaCreateRequest, IAreaResponse, IAreaUpdateRequest, ICountResponse, ILoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class AreaApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.AREAS;
  private prefixCount = 'count';

  createArea(requestBody: IAreaCreateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<IAreaResponse>(url, requestBody);
  }

  deleteAreaById(areaId: string) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${areaId}`);

    return this.httpClient.delete<void>(url);
  }

  getAllAreas(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IAreaResponse[]>(url, {params: queryParams});
  }

  getCountAllAreas(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  updateAreaById(areaId: string, requestBody: IAreaUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${areaId}`);

    return this.httpClient.patch<IAreaResponse>(url, requestBody);
  }
}
