import {PermissionConstant} from '@rcms/constants';
import {TEntity, TPermissionsScreenName} from '@rcms/types';

export class PermissionUtil {
  static getPermissionScopeValues(screenName: TPermissionsScreenName): TEntity[] {
    const scopeByKey = PermissionConstant.SCREEN_SCOPE[screenName] ?? {};

    return Object.values(scopeByKey);
  }
}
