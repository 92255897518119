import {Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {CompanyService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ICompanyCreateRequest, ICompanyResponse, IErrorResponse} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-update-modal',
  templateUrl: './company-create-modal.component.html',
  styleUrls: ['./company-create-modal.component.scss'],
})
export class CompanyCreateModalComponent extends BaseModalComponent<ICompanyResponse> {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private companyService = inject(CompanyService);

  form!: FormGroup;
  hasChange = false;
  isExistName = false;
  isInvalid = false;
  isLoading = false;

  constructor() {
    super();
    this.initForm();
  }

  focusInputName() {
    this.isExistName = false;
  }

  initForm() {
    this.form = this.fb.group({
      name: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required],
      ],
      description: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512)]],
    });
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    this.isInvalid = this.form.invalid || this.isExistName;
    if (this.hasChange && !this.isInvalid) {
      this.isLoading = true;
      const requestBody: ICompanyCreateRequest = this.form.value;
      this.companyService.createCompanyObs(requestBody).subscribe({
        next: () => this.onCreateCompanySuccess(),
        error: (error) => this.onCreateCompanyFailed(error),
      });
    }
  }

  onCreateCompanyFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistName = true;
    } else {
      this.setFeedbackCreateFailed(ModalConstant.ENTITY.COMPANY);
    }
  }

  onCreateCompanySuccess() {
    this.isLoading = false;
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.COMPANY);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.COMPANY_CREATE);
  }
}
