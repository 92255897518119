<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputPasswordOld" class="form-label">Mật khẩu hiện tại </label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="password"
          class="form-control"
          id="inputPasswordOld"
          placeholder="Nhập mật khẩu hiện tại"
          formControlName="passwordOld"
          [ngClass]="{
            'is-invalid': (isInvalid || form.get('passwordOld')?.touched) && form.get('passwordOld')?.invalid,
          }"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('passwordOld')?.errors?.['minlength']">Độ dài ít nhất 6 ký tự</p>
          <p *ngIf="form.get('passwordOld')?.errors?.['maxlength']">Độ dài quá 72 ký tự</p>
          <p *ngIf="form.get('passwordOld')?.errors?.['required']">Mật khẩu hiện tại không được để trống</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputPasswordNew" class="form-label">Mật khẩu mới</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="password"
          class="form-control"
          id="inputPasswordNew"
          placeholder="Nhập mật khẩu mới"
          formControlName="passwordNew"
          [ngClass]="{
            'is-invalid':
              ((isInvalid || form.get('passwordNew')?.touched) && form.get('passwordNew')?.invalid) ||
              form.errors?.['samePassword'],
          }"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('passwordNew')?.errors?.['minlength']">Độ dài ít nhất 6 ký tự</p>
          <p *ngIf="form.get('passwordNew')?.errors?.['maxlength']">Độ dài quá 72 ký tự</p>
          <p *ngIf="form.get('passwordNew')?.errors?.['required']">Mật khẩu mới không được để trống</p>
          <p *ngIf="form.errors?.['samePassword']">Mật khẩu mới không được trùng với mật khẩu cũ</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputPasswordNewConfirm" class="form-label">Xác nhận mật khẩu mới</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="password"
          class="form-control"
          id="inputPasswordNewConfirm"
          placeholder="Xác nhận mật khẩu mới"
          formControlName="passwordNewConfirm"
          [ngClass]="{
            'is-invalid': (isInvalid || form.get('passwordNewConfirm')?.touched) && form.errors?.['differentPassword'],
          }"
        />
        <div class="invalid-feedback">Mật khẩu xác nhận không khớp</div>
      </div>
    </form>
  </div>
  <app-footer-modal [isLoading]="isLoading" (eventCancel)="onClose()" (eventSubmit)="onSave()"></app-footer-modal>
</ng-container>
<app-feedback-modal
  [feedback]="feedback()"
  (eventBack)="onBack()"
  (eventAccept)="onAccept()"
  (eventClose)="onClose()"
></app-feedback-modal>
