import {Component, effect, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {GlobalSignal, PermissionSignal} from '@rcms/states/signals';
import {IUserResponse, TPermissionsDataEntity} from '@rcms/types';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-detail-modal',
  templateUrl: './user-detail-modal.component.html',
  styleUrls: ['./user-detail-modal.component.scss'],
})
export class UserDetailModalComponent extends BaseModalComponent<IUserResponse> implements OnInit {
  permissionsUser!: TPermissionsDataEntity;
  user!: IUserResponse;

  constructor() {
    super();
    effect(() => {
      this.permissionsUser = PermissionSignal.permissionsUser();
    });
  }

  ngOnInit() {
    this.user = this.data;
  }

  onOpenModalUserUpdate() {
    this.onClose();
    GlobalSignal.modalData.set({modalName: ModalConstant.NAME.USER_UPDATE, data: this.user});
  }
}
