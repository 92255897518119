import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {AreaService, CompanyService} from '@rcms/services';
import {AreaStore} from '@rcms/states/akita/stores';
import {IAreaResponse, IAreaUpdateRequest, ICompanyResponse, IErrorResponse} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-area-update-modal',
  templateUrl: './area-update-modal.component.html',
  styleUrls: ['./area-update-modal.component.scss'],
})
export class AreaUpdateModalComponent extends BaseModalComponent<IAreaResponse> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private areaService = inject(AreaService);
  private areaStore = inject(AreaStore);
  private companyService = inject(CompanyService);

  form!: FormGroup;
  hasChange = false;
  isExistName = false;
  isLoading = false;
  companyDatasets: ICompanyResponse[] = [];
  area!: IAreaResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.area = this.data;
    this.patchDataForm(this.area);
    this.getCompanyDatasets();
  }

  getCompanyDatasets() {
    this.companyService.getAllCompaniesObs({}).subscribe({
      next: (companies) => this.companyDatasets = companies,
    });
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      companyMaintenanceId: ['', [Validators.required]],
      companyObserverId: ['', [Validators.required]],
    });
  }

  patchDataForm(data: IAreaResponse) {
    this.form.patchValue({
      name: data.name,
      companyMaintenanceId: data.companyMaintenanceId,
      companyObserverId: data.companyObserverId,
    });
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    const isInvalid = this.form.invalid || this.isExistName;
    if (this.hasChange && !isInvalid) {
      this.isLoading = true;
      const requestBody: IAreaUpdateRequest = this.form.value;
      this.areaService.updateAreaByIdObs(this.area.id, requestBody).subscribe({
        next: (data) => this.onUpdateAreaSuccess(data),
        error: (error) => this.onUpdateAreaFailed(error),
      });
    }
  }

  onUpdateAreaFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistName = true;
    } else {
      this.setFeedbackUpdateFailed(ModalConstant.ENTITY.AREA);
    }
  }

  onUpdateAreaSuccess(area: IAreaResponse) {
    this.areaStore.update(area.id, area);
    this.isLoading = false;
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.AREA);
  }
}
