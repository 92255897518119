import {Component, DestroyRef, EventEmitter, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, CoreConstant, FileConstant} from '@rcms/constants';
import {FileService, UserService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IFileStorageUploadTokenRequest, IUserResponse, IUserUpdateMyProfileRequest} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-update-my-profile-modal',
  templateUrl: './user-update-my-profile-modal.component.html',
  styleUrls: ['./user-update-my-profile-modal.component.scss'],
})
export class UserUpdateMyProfileModalComponent extends BaseModalComponent<IUserResponse> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private fileService = inject(FileService);
  private userService = inject(UserService);

  eventUpload$: EventEmitter<IFileStorageUploadTokenRequest> = new EventEmitter();

  form!: FormGroup;
  hasChangeName = false;
  hasChangeAvatar = false;
  isLoading = false;
  userAvatarUrl = '';
  user!: IUserResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.user = this.data;
    this.userAvatarUrl = this.fileService.getImageThumbnailUrl({
      fileStorage: this.user.avatar,
      thumbnailSize: {height: 100, width: 100},
      defaultUrl: '',
    });
    this.patchDataForm(this.user);
  }

  initForm() {
    this.form = this.fb.group({
      fullName: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
    });
  }

  patchDataForm(data: IUserResponse) {
    this.form.patchValue({fullName: data.fullName});
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChangeName = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    if (this.hasChangeAvatar) {
      this.isLoading = true;
      this.eventUpload$.next({
        companyId: this.user.companyId,
        entity: FileConstant.UPLOAD_ENTITY.USER,
        entityId: this.user.id,
        fieldName: FileConstant.UPLOAD_FIELD.AVATAR,
      });
    } else {
      if (this.hasChangeName && !this.form.invalid) {
        this.isLoading = true;
        const requestBody: IUserUpdateMyProfileRequest = this.form.value;
        this.userService.updateUserMyProfileObs(requestBody).subscribe({
          next: (data) => this.onUpdateUserMyProfileSuccess(data),
          error: () => this.onUpdateUserMyProfileFailed(),
        });
      }
    }
  }

  onUpdateUserMyProfileFailed() {
    this.isLoading = false;
    this.setFeedbackUpdateFailed();
  }

  onUpdateUserMyProfileSuccess(user: IUserResponse) {
    const userAvatarUrl = this.fileService.getImageThumbnailUrl({
      fileStorage: user.avatar,
      thumbnailSize: {height: 100, width: 100},
      defaultUrl: CoreConstant.IMAGES.USER_AVATAR,
    });
    GlobalSignal.me.set(user);
    GlobalSignal.meAvatarUrl.set(userAvatarUrl);
    this.isLoading = false;
    this.setFeedbackUpdateSuccess();
  }

  onFileUploadChange(fileSource: string | ArrayBuffer | null) {
    this.hasChangeAvatar = !!fileSource;
  }

  onUploadSuccess(originName: string) {
    const requestBody: IUserUpdateMyProfileRequest = {avatarOriginalName: originName};
    if (this.hasChangeName && !this.form.invalid) {
      const {fullName} = this.form.value;
      requestBody.fullName = fullName;
    }
    this.isLoading = true;
    this.userService.updateUserMyProfileObs(requestBody).subscribe({
      next: (data) => this.onUpdateUserMyProfileSuccess(data),
      error: () => this.onUpdateUserMyProfileFailed(),
    });
  }
}
