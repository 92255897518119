import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TRouteName} from '@rcms/types';
import {RouteUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class NavigationService {
  private router = inject(Router);

  navigateTo(routeName: TRouteName): void {
    const routePath = RouteUtil.getPathByRouteName(routeName);
    this.router.navigate([routePath]).catch((e) => {
      throw e;
    });
  }

  async navigateToAsync(routeName: TRouteName): Promise<boolean> {
    const routePath = RouteUtil.getPathByRouteName(routeName);
    return this.router.navigate([routePath]);
  }
}
