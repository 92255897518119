import {resetStores} from '@datorama/akita';
import {LocalStorage} from '@rcms/states/local';
import {GlobalSignal, PermissionSignal} from '@rcms/states/signals';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class StateService {
  static clearAll() {
    GlobalSignal.clear();
    PermissionSignal.clear();
    LocalStorage.clear();
    resetStores();
  }
}
