import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant, UserConstant} from '@rcms/constants';
import {CompanyService, RoleService, UserService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ICompanyResponse, IErrorResponse, IRoleResponse, IUserCreateRequest} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-create-modal',
  templateUrl: './user-create-modal.component.html',
  styleUrls: ['./user-create-modal.component.scss'],
})
export class UserCreateModalComponent extends BaseModalComponent<null> implements OnInit {
  private companyService = inject(CompanyService);
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private roleService = inject(RoleService);
  private userService = inject(UserService);

  companyDatasets: ICompanyResponse[] = [];
  form!: FormGroup;
  hasChange = false;
  isExistUsername = false;
  isInvalid = false;
  isLoading = false;
  roleDatasets: IRoleResponse[] = [];

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.getCompanyDatasets();
    this.getRoleDatasets();
  }

  focusInputUsername() {
    this.isExistUsername = false;
  }

  getCompanyDatasets() {
    this.companyService.getAllCompaniesObs({}).subscribe({
      next: (companies) => this.companyDatasets = companies,
    });
  }

  getRoleDatasets() {
    this.roleService.getAllRolesObs({}).subscribe({
      next: (roles) => this.roleDatasets = roles,
    });
  }

  initForm() {
    this.form = this.fb.group({
      username: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      password: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      fullName: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      roleId: ['', Validators.required],
      companyId: ['', Validators.required],
      active: [true],
    });
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    this.isInvalid = this.form.invalid || this.isExistUsername;
    if (this.hasChange && !this.isInvalid) {
      this.isLoading = true;
      const requestBody: IUserCreateRequest = this.form.value;
      this.userService.createUserObs(requestBody).subscribe({
        next: () => this.onCreateUserSuccess(),
        error: (error) => this.onCreateUserFailed(error),
      });
    }
  }

  onCreateUserFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistUsername = true;
    } else {
      this.setFeedbackCreateFailed(ModalConstant.ENTITY.USER);
    }
  }

  onCreateUserSuccess() {
    this.isLoading = false;
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.USER);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.USER_CREATE);
  }
}
