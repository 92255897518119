import {RouteConstant} from '@rcms/constants';
import {TRouteName} from '@rcms/types';

const routesPath: Record<TRouteName, string> = {
  [RouteConstant.ROUTE_NAME.AREAS]: `${RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT}/${RouteConstant.ROUTE_NAME.AREAS}`,
  [RouteConstant.ROUTE_NAME.AUTH]: RouteConstant.ROUTE_NAME.AUTH,
  [RouteConstant.ROUTE_NAME.COMPANIES]: RouteConstant.ROUTE_NAME.COMPANIES,
  [RouteConstant.ROUTE_NAME.FORBIDDEN]: RouteConstant.ROUTE_NAME.FORBIDDEN,
  [RouteConstant.ROUTE_NAME.HOME]: RouteConstant.ROUTE_NAME.HOME,
  [RouteConstant.ROUTE_NAME.LOGIN]: `${RouteConstant.ROUTE_NAME.AUTH}/${RouteConstant.ROUTE_NAME.LOGIN}`,
  [RouteConstant.ROUTE_NAME.LOGOUT]: `${RouteConstant.ROUTE_NAME.AUTH}/${RouteConstant.ROUTE_NAME.LOGOUT}`,
  [RouteConstant.ROUTE_NAME.MY_PROFILE]: RouteConstant.ROUTE_NAME.MY_PROFILE,
  [RouteConstant.ROUTE_NAME.ROLES]: `${RouteConstant.ROUTE_NAME.USERS_MANAGEMENT}/${RouteConstant.ROUTE_NAME.ROLES}`,
  [RouteConstant.ROUTE_NAME.STATIONS]:
    `${RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT}/${RouteConstant.ROUTE_NAME.STATIONS}`,
  [RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT]: RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT,
  [RouteConstant.ROUTE_NAME.TRAIN_STATIONS]:
    `${RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT}/${RouteConstant.ROUTE_NAME.TRAIN_STATIONS}`,
  [RouteConstant.ROUTE_NAME.USERS]: `${RouteConstant.ROUTE_NAME.USERS_MANAGEMENT}/${RouteConstant.ROUTE_NAME.USERS}`,
  [RouteConstant.ROUTE_NAME.USERS_MANAGEMENT]: RouteConstant.ROUTE_NAME.USERS_MANAGEMENT,
};

export class RouteUtil {
  static getPathByRouteName(routeName: TRouteName): string {
    return routesPath[routeName];
  }
}
