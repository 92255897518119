export class RouteConstant {
  static readonly ROUTE_NAME = {
    AREAS: 'areas',
    AUTH: 'auth',
    COMPANIES: 'companies',
    FORBIDDEN: '403',
    HOME: '',
    LOGIN: 'login',
    LOGOUT: 'logout',
    MY_PROFILE: 'my-profile',
    ROLES: 'roles',
    STATIONS: 'stations',
    STATIONS_MANAGEMENT: 'stations-management',
    TRAIN_STATIONS: 'train-stations',
    USERS: 'users',
    USERS_MANAGEMENT: 'users-management',
  } as const;
}
