export class CoreConstant {
  static readonly APP_NAME = 'HỆ THỐNG GIÁM SÁT ĐƯỜNG NGANG CÓ GÁC';

  static readonly COMPANY_NAME = 'THÔNG TIN TÍN HIỆU ĐƯỜNG SẮT SÀI GÒN';

  static readonly COMPANY_TYPE = 'CÔNG TY CỔ PHẦN';

  static readonly IMAGES = {
    LOGO: './assets/images/logo.jpeg',
    USER_AVATAR: './assets/icons/avatar.png',
  } as const;
}
