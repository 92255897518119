import {CommonConstant} from '@rcms/constants';
import {TFeedbackAction} from '@rcms/types';
import CryptoES from 'crypto-es';

const feedbackActionToMessage: Record<TFeedbackAction, string> = {
  [CommonConstant.FEEDBACK.ACTION.CREATE]: CommonConstant.FEEDBACK.MESSAGE.CREATE,
  [CommonConstant.FEEDBACK.ACTION.DELETE]: CommonConstant.FEEDBACK.MESSAGE.DELETE,
  [CommonConstant.FEEDBACK.ACTION.UPDATE]: CommonConstant.FEEDBACK.MESSAGE.UPDATE,
};

export class CommonUtil {
  static aesDecrypt(ciphertext: string, passphrase: string): string {
    try {
      const decrypted = CryptoES.AES.decrypt(ciphertext, passphrase).toString(CryptoES.enc.Utf8);
      return decrypted.toString();
    } catch (err) {
      return '';
    }
  }

  static getFeedbackMessageFailed(action: TFeedbackAction, entity?: string, messageSuffix?: string): string {
    return [
      feedbackActionToMessage[action],
      entity ? ` ${entity.toLowerCase()}` : '',
      ` ${CommonConstant.FEEDBACK.MESSAGE.FAILED}!`,
      messageSuffix ? ` ${messageSuffix}` : '',
    ].join('');
  }

  static getFeedbackMessageSuccess(action: TFeedbackAction, entity?: string, messageSuffix?: string): string {
    return [
      feedbackActionToMessage[action],
      entity ? ` ${entity.toLowerCase()}` : '',
      ` ${CommonConstant.FEEDBACK.MESSAGE.SUCCESS}!`,
      messageSuffix ? ` ${messageSuffix}` : '',
    ].join('');
  }

  static getItemsPerPageValues(): number[] {
    return Object.values(CommonConstant.PAGING.ITEMS_PER_PAGE);
  }

  static getPreviousPage(page: number): number {
    return Math.max(page - 1, 0);
  }

  static getStartPageDefault(): number {
    return CommonConstant.PAGING.START_PAGE_DEFAULT;
  }

  static jsonReplacer(key: string, value: unknown) {
    // Handling value contains a circular reference
    if (key === 'parent') {
      return null; // Replace circular reference with null
    }
    // Handling BigInt Values
    if (typeof value === 'bigint') {
      return value.toString();
    }
    return value;
  }
}
