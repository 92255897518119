import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ApiConstant} from '@rcms/constants';
import {ICompanyResponse, ICompanyUpdateRequest, ICountResponse, ILoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class CompanyApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.COMPANIES;
  private prefixCount = 'count';

  createCompany(requestBody: ICompanyUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<ICompanyResponse>(url, requestBody);
  }

  deleteCompanyById(companyId: string) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${companyId}`);

    return this.httpClient.delete<void>(url);
  }

  getAllCompanies(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<ICompanyResponse[]>(url, {params: queryParams});
  }

  getCountAllCompanies(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  updateCompanyById(companyId: string, requestBody: ICompanyUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${companyId}`);

    return this.httpClient.patch<ICompanyResponse>(url, requestBody);
  }
}
