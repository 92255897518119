import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {TrainStationService} from '@rcms/services';
import {TrainStationStore} from '@rcms/states/akita/stores';
import {IErrorResponse, ITrainStationResponse, ITrainStationUpdateRequest} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-train-station-update-modal',
  templateUrl: './train-station-update-modal.component.html',
  styleUrls: ['./train-station-update-modal.component.scss'],
})
export class TrainStationUpdateModalComponent extends BaseModalComponent<ITrainStationResponse> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private trainStationService = inject(TrainStationService);
  private trainStationStore = inject(TrainStationStore);

  form!: FormGroup;
  hasChange = false;
  isExistName = false;
  isLoading = false;
  trainStation!: ITrainStationResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.trainStation = this.data;
    this.patchDataForm(this.trainStation);
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      address: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512), Validators.required]],
    });
  }

  patchDataForm(data: ITrainStationResponse) {
    this.form.patchValue({name: data.name, address: data.address});
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    const isInvalid = this.form.invalid || this.isExistName;
    if (this.hasChange && !isInvalid) {
      this.isLoading = true;
      const requestBody: ITrainStationUpdateRequest = this.form.value;
      this.trainStationService.updateTrainStationByIdObs(this.trainStation.id, requestBody).subscribe({
        next: (data) => this.onUpdateTrainStationSuccess(data),
        error: (error) => this.onUpdateTrainStationFailed(error),
      });
    }
  }

  onUpdateTrainStationFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistName = true;
    } else {
      this.setFeedbackUpdateFailed(ModalConstant.ENTITY.TRAIN_STATION);
    }
  }

  onUpdateTrainStationSuccess(trainStation: ITrainStationResponse) {
    this.trainStationStore.update(trainStation.id, trainStation);
    this.isLoading = false;
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.TRAIN_STATION);
  }
}
