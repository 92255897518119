import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {ICountResponse, ILoopBackFilters, IRoleResponse} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class RoleApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.ROLES;
  private prefixCount = 'count';

  getAllRoles(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IRoleResponse[]>(url, {params: queryParams});
  }

  getCountAllRoles(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }
}
