import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {
  ICountResponse,
  ILoopBackFilters,
  IUserCreateRequest,
  IUserResponse,
  IUserUpdateMyPasswordRequest,
  IUserUpdateMyProfileRequest,
  IUserUpdateRequest,
} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class UserApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.USERS;
  private prefixCount = 'count';
  private prefixMe = 'me';
  private prefixPassword = 'password';

  createUser(requestBody: IUserCreateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<IUserResponse>(url, requestBody);
  }

  deleteUserById(userId: string) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${userId}`);

    return this.httpClient.delete<void>(url);
  }

  getAllUsers(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IUserResponse[]>(url, {params: queryParams});
  }

  getCountAllUsers(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  getMe() {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixMe}`);
    return this.httpClient.get<IUserResponse>(url);
  }

  updateUserById(userId: string, requestBody: IUserUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${userId}`);

    return this.httpClient.patch<IUserResponse>(url, requestBody);
  }

  updateUserMyPassword(requestBody: IUserUpdateMyPasswordRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixPassword}`);

    return this.httpClient.patch<void>(url, requestBody);
  }

  updateUserMyProfile(requestBody: IUserUpdateMyProfileRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixMe}`);

    return this.httpClient.patch<IUserResponse>(url, requestBody);
  }
}
