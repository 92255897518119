import {inject, Injectable} from '@angular/core';
import {AuthApi} from '@rcms/apis';
import {IUserLoginRequest, IUserLoginResponse, IUserLogoutRequest} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthService {
  private authApi = inject(AuthApi);

  loginObs(requestBody: IUserLoginRequest): Observable<IUserLoginResponse> {
    return this.authApi.login(requestBody);
  }

  logoutObs(requestBody: IUserLogoutRequest): Observable<void> {
    return this.authApi.logout(requestBody);
  }
}
