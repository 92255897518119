<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputName" class="form-label">Tên khu vực</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputName"
          placeholder="Nhập tên"
          formControlName="name"
          [ngClass]="{'is-invalid': form.get('name')?.invalid || isExistName}"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('name')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('name')?.errors?.['required']">Tên không được để trống</p>
          <p *ngIf="isExistName">Tên đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Xí nghiệp quản lý</p>
          <p class="mx-1 text-danger">*</p>
        </div>
        <div class="w-100">
          <select
            class="form-select"
            aria-label="select company name"
            formControlName="companyMaintenanceId"
            [ngClass]="{'is-invalid': form.get('companyMaintenanceId')?.invalid}"
          >
            <ng-container *ngFor="let company of companyDatasets">
              <option [value]="company.id">{{ company.name }}</option>
            </ng-container>
          </select>
          <div class="invalid-feedback">Chọn một đơn vị</div>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Đơn vị cầu đường</p>
          <p class="mx-1 text-danger">*</p>
        </div>
        <div class="w-100">
          <select
            class="form-select"
            aria-label="select company name"
            formControlName="companyObserverId"
            [ngClass]="{'is-invalid': form.get('companyObserverId')?.invalid}"
          >
            <ng-container *ngFor="let company of companyDatasets">
              <option [value]="company.id">{{ company.name }}</option>
            </ng-container>
          </select>
          <div class="invalid-feedback">Chọn một đơn vị</div>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>
