import {Component, inject} from '@angular/core';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {ModalConstant, RouteConstant, UserConstant} from '@rcms/constants';
import {FormValidatorService, NavigationService, StateService, UserService} from '@rcms/services';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-update-my-password-modal',
  templateUrl: './user-update-my-password-modal.component.html',
  styleUrls: ['./user-update-my-password-modal.component.scss'],
})
export class UserUpdateMyPasswordModalComponent extends BaseModalComponent<null> {
  private fb = inject(NonNullableFormBuilder);
  private navigationService = inject(NavigationService);
  private userService = inject(UserService);

  form!: FormGroup;
  isInvalid = false;
  isLoading = false;

  constructor() {
    super();
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      passwordOld: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      passwordNew: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      passwordNewConfirm: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
    }, {validators: [FormValidatorService.differentPasswordValidation, FormValidatorService.samePasswordValidation]});
  }

  onAccept() {
    this.onClose();
    this.navigationService.navigateToAsync(RouteConstant.ROUTE_NAME.LOGIN).then(() => StateService.clearAll());
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    this.isInvalid = this.form.invalid;
    if (!this.isInvalid) {
      this.isLoading = true;
      const {passwordNew, passwordOld} = this.form.value;
      this.userService.updateUserMyPasswordObs({passwordOld, passwordNew}).subscribe({
        next: () => this.onUpdateUserMyPasswordSuccess(),
        error: () => this.onUpdateUserMyPasswordFailed(),
      });
    }
  }

  onUpdateUserMyPasswordFailed() {
    this.isLoading = false;
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.PASSWORD);
  }

  onUpdateUserMyPasswordSuccess() {
    this.isLoading = false;
    this.setFeedbackUpdateSuccess(
      ModalConstant.ENTITY.PASSWORD,
      'Vui lòng đăng nhập lại để tiếp tục sử dụng dịch vụ.',
      {btnAcceptVisible: true, btnCloseVisible: false},
    );
  }
}
