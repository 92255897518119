import {inject, Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {
  IFileStorageDownloadRequest,
  IFileStorageUploadRequest,
  IFileStorageUploadResponse,
  IFileStorageUploadTokenRequest,
  IFileStorageUploadTokenResponse,
} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class FileApi {
  private httpClient = inject(HttpClient);
  private httpBackend = inject(HttpBackend);

  private httpClientCustom: HttpClient;

  constructor() {
    this.httpClientCustom = new HttpClient(this.httpBackend);
  }

  private basePath = ApiConstant.API_ENTITY.FILES;
  private prefixDownload = 'download';
  private prefixUpload = 'upload';
  private prefixUploadToken = 'upload-token';

  getFileUrl(originalName: string, requestBody: IFileStorageDownloadRequest) {
    const query = ApiUtil.convert2HttpParamsWithQuery(requestBody);
    return ApiUtil.generateCachedUrlFromPath(`${this.basePath}/${this.prefixDownload}/${originalName}?${query}`);
  }

  getFileUploadToken(requestBody: IFileStorageUploadTokenRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixUploadToken}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery(requestBody);

    return this.httpClient.get<IFileStorageUploadTokenResponse>(url, {params: queryParams});
  }

  uploadFile(accessToken: string, formData: FormData, requestBody: IFileStorageUploadRequest) {
    const queryParams = ApiUtil.convert2HttpParamsWithQuery(requestBody);
    const url = ApiUtil.generateCachedUrlFromPath(`${this.basePath}/${this.prefixUpload}?${queryParams}`);

    const headersUpload = new HttpHeaders().set('Authorization', accessToken);
    return this.httpClientCustom.post<IFileStorageUploadResponse>(
      url,
      formData,
      {reportProgress: true, observe: 'events', headers: headersUpload},
    );
  }
}
