import {Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {TrainStationService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IErrorResponse, ITrainStationCreateRequest, ITrainStationResponse} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-train-station-create-modal',
  templateUrl: './train-station-create-modal.component.html',
  styleUrls: ['./train-station-create-modal.component.scss'],
})
export class TrainStationCreateModalComponent extends BaseModalComponent<ITrainStationResponse> {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private trainStationService = inject(TrainStationService);

  form!: FormGroup;
  hasChange = false;
  isExistName = false;
  isInvalid = false;
  isLoading = false;

  constructor() {
    super();
    this.initForm();
  }

  focusInputName() {
    this.isExistName = false;
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      address: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512), Validators.required]],
    });
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    this.isInvalid = this.form.invalid || this.isExistName;
    if (this.hasChange && !this.isInvalid) {
      this.isLoading = true;
      const requestBody: ITrainStationCreateRequest = this.form.value;
      this.trainStationService.createTrainStationObs(requestBody).subscribe({
        next: () => this.onCreateTrainStationSuccess(),
        error: (error) => this.onCreateTrainStationFailed(error),
      });
    }
  }

  onCreateTrainStationFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistName = true;
    } else {
      this.setFeedbackCreateFailed(ModalConstant.ENTITY.TRAIN_STATION);
    }
  }

  onCreateTrainStationSuccess() {
    this.isLoading = false;
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.TRAIN_STATION);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.TRAIN_STATION_CREATE);
  }
}
