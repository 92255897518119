import {inject, Injectable} from '@angular/core';
import {AreaApi} from '@rcms/apis';
import {IAreaCreateRequest, IAreaResponse, IAreaUpdateRequest, ICountResponse, ILoopBackFilters} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AreaService {
  private areaApi = inject(AreaApi);

  createAreaObs(requestBody: IAreaCreateRequest): Observable<IAreaResponse> {
    return this.areaApi.createArea(requestBody);
  }

  deleteAreaByIdObs(areaId: string): Observable<void> {
    return this.areaApi.deleteAreaById(areaId);
  }

  getAllAreasObs(filters: ILoopBackFilters): Observable<IAreaResponse[]> {
    return this.areaApi.getAllAreas(filters);
  }

  getCountAllAreasObs(where: object): Observable<ICountResponse> {
    return this.areaApi.getCountAllAreas(where);
  }

  updateAreaByIdObs(areaId: string, requestBody: IAreaUpdateRequest): Observable<IAreaResponse> {
    return this.areaApi.updateAreaById(areaId, requestBody);
  }
}
