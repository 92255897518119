import {Component, effect, ElementRef, inject, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GlobalSignal} from '@rcms/states/signals';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  private ngbModal = inject(NgbModal);

  @ViewChild('loadingModal') loadingModal!: ElementRef;

  loadingModalRef!: NgbModalRef;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    modalDialogClass: 'rcms-modal__loading',
  };

  constructor() {
    this.listenLoadingChanges();
  }

  listenLoadingChanges() {
    effect(() => {
      const isLoading = GlobalSignal.loading();
      if (isLoading) {
        this.loadingModalRef = this.ngbModal.open(this.loadingModal, this.ngbModalOptions);
      } else {
        if (this.loadingModalRef) {
          this.loadingModalRef.close();
        }
      }
    });
  }
}
