import {HelperConstant} from '@rcms/constants/helper.constant';

export class CommonConstant {
  static readonly ENTITY = HelperConstant.ENTITY;

  static readonly FEEDBACK = {
    ACTION: {CREATE: 'CREATE', DELETE: 'DELETE', UPDATE: 'UPDATE'},
    MESSAGE: {CREATE: 'Tạo mới', DELETE: 'Xoá', FAILED: 'thất bại', SUCCESS: 'thành công', UPDATE: 'Cật nhật'},
    STATE: {ERROR: 'error', INFO: 'info', SUCCESS: 'success', WARNING: 'warning'},
  } as const;

  static readonly LIMIT = {LENGTH_64: 64, LENGTH_256: 256, LENGTH_512: 512, LENGTH_1024: 1024} as const;

  static readonly PAGING = {ITEMS_PER_PAGE: [5, 10, 20, 30, 50, 100], START_PAGE_DEFAULT: 1} as const;
}
