import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {TrainStationService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ITrainStationResponse} from '@rcms/types';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-train-station-delete-modal',
  templateUrl: './train-station-delete-modal.component.html',
  styleUrls: ['./train-station-delete-modal.component.scss'],
})
export class TrainStationDeleteModalComponent extends BaseModalComponent<ITrainStationResponse> implements OnInit {
  private trainStationService = inject(TrainStationService);

  isLoading = false;
  trainStation!: ITrainStationResponse;

  ngOnInit() {
    this.trainStation = this.data;
  }

  onBack() {
    this.clearFeedback();
  }

  onDelete() {
    this.isLoading = true;
    this.trainStationService.deleteTrainStationByIdObs(this.trainStation.id).subscribe({
      next: () => this.onDeleteTrainStationSuccess(),
      error: () => this.onDeleteTrainStationFailed(),
    });
  }

  onDeleteTrainStationFailed() {
    this.isLoading = false;
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.TRAIN_STATION);
  }

  onDeleteTrainStationSuccess() {
    this.isLoading = false;
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.TRAIN_STATION);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.TRAIN_STATION_DELETE);
  }
}
