import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {CompanyService} from '@rcms/services';
import {CompanyStore} from '@rcms/states/akita/stores';
import {ICompanyResponse, ICompanyUpdateRequest, IErrorResponse} from '@rcms/types';
import * as _ from 'lodash-es';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-user-update-modal',
  templateUrl: './company-update-modal.component.html',
  styleUrls: ['./company-update-modal.component.scss'],
})
export class CompanyUpdateModalComponent extends BaseModalComponent<ICompanyResponse> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private fb = inject(NonNullableFormBuilder);
  private companyService = inject(CompanyService);
  private companyStore = inject(CompanyStore);

  form!: FormGroup;
  hasChange = false;
  isExistName = false;
  isLoading = false;
  company!: ICompanyResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.company = this.data;
    this.patchDataForm(this.company);
  }

  focusInputName() {
    this.isExistName = false;
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      description: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512)]],
    });
  }

  patchDataForm(data: ICompanyResponse) {
    this.form.patchValue({name: data.name, description: data.description});
    this.onFormChangeValues();
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_.isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    const isInvalid = this.form.invalid || this.isExistName;
    if (this.hasChange && !isInvalid) {
      this.isLoading = true;
      const requestBody: ICompanyUpdateRequest = this.form.value;
      this.companyService.updateCompanyByIdObs(this.company.id, requestBody).subscribe({
        next: (data) => this.onUpdateCompanySuccess(data),
        error: (error) => this.onUpdateCompanyFailed(error),
      });
    }
  }

  onUpdateCompanyFailed(error: IErrorResponse) {
    this.isLoading = false;
    if (error.code === ErrorConstant.API_ERROR_CODE.DATA_EXIST) {
      this.isExistName = true;
    } else {
      this.setFeedbackUpdateFailed(ModalConstant.ENTITY.COMPANY);
    }
  }

  onUpdateCompanySuccess(company: ICompanyResponse) {
    this.companyStore.update(company.id, company);
    this.isLoading = false;
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.COMPANY);
  }
}
