import {LocalStorageConstant} from '@rcms/constants';
import {IUserLoginResponse} from '@rcms/types';

abstract class LocalStorageBase {
  static clear() {
    localStorage.clear();
  }

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static setItem(key: string, data: string) {
    return localStorage.setItem(key, data);
  }

  static removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}

export class LocalStorage extends LocalStorageBase {
  static setLoggedIn(data: IUserLoginResponse) {
    const {token} = data;
    this.setToken(token);
    this.setTokenData(data);
  }

  static getToken() {
    return this.getItem(LocalStorageConstant.LS_TOKEN);
  }

  private static setToken(token: string) {
    this.setItem(LocalStorageConstant.LS_TOKEN, token);
  }

  private static setTokenData(data: IUserLoginResponse) {
    try {
      const dataStorage = JSON.stringify(data);
      this.setItem(LocalStorageConstant.LS_TOKEN_DATA, dataStorage);
    } catch (err) { /* empty */ }
  }
}
