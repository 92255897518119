import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalStorage} from '@rcms/states/local';
import {GlobalSignal} from '@rcms/states/signals';
import {Observable} from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = GlobalSignal.token() || LocalStorage.getToken();

    const modifiedRequest = request.clone({
      setHeaders: {
        'Authorization': token ?? '',
        'Content-Type': 'application/json',
      },
    });
    return next.handle(modifiedRequest);
  }
}
