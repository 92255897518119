import {EntityState, EntityStore} from '@datorama/akita';

export interface IEntityState<TEntity> extends EntityState<TEntity, string> {
  totalItems: number;
}

export class BaseStore<TEntity> extends EntityStore<IEntityState<TEntity>> {
  constructor() {
    super({totalItems: 0});
  }
}
