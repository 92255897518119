import {inject, Injectable} from '@angular/core';
import {UserApi} from '@rcms/apis';
import {GlobalSignal} from '@rcms/states/signals';
import {
  ICountResponse,
  ILoopBackFilters,
  IUserCreateRequest,
  IUserResponse,
  IUserUpdateMyPasswordRequest,
  IUserUpdateMyProfileRequest,
  IUserUpdateRequest,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService {
  private userApi = inject(UserApi);

  createUserObs(requestBody: IUserCreateRequest): Observable<IUserResponse> {
    return this.userApi.createUser(requestBody);
  }

  deleteUserByIdObs(userId: string): Observable<void> {
    return this.userApi.deleteUserById(userId);
  }

  getAllUsersObs(filters: ILoopBackFilters): Observable<IUserResponse[]> {
    return this.userApi.getAllUsers(filters);
  }

  getCountAllUsersObs(where: object): Observable<ICountResponse> {
    return this.userApi.getCountAllUsers(where);
  }

  getMeObs(): Observable<IUserResponse> {
    GlobalSignal.loading.set(true);
    return this.userApi.getMe();
  }

  updateUserByIdObs(userId: string, requestBody: IUserUpdateRequest): Observable<IUserResponse> {
    return this.userApi.updateUserById(userId, requestBody);
  }

  updateUserMyPasswordObs(requestBody: IUserUpdateMyPasswordRequest): Observable<void> {
    return this.userApi.updateUserMyPassword(requestBody);
  }

  updateUserMyProfileObs(requestBody: IUserUpdateMyProfileRequest): Observable<IUserResponse> {
    return this.userApi.updateUserMyProfile(requestBody);
  }
}
